import { $, DialogStore, Readable, RouterStore, ViewContext } from "@manyducks.co/dolla";
import styles from "./PlacePanel.module.css";
import { NavMenu } from "Workspace/NavMenu/NavMenu";
import { ThemeStore } from "@stores/ThemeStore";
import { NavStore } from "@stores/NavStore";
import { BreakpointStore } from "@stores/BreakpointStore";
import { SearchMenu } from "@views/SearchMenu";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TaskEditDialog } from "@views/TaskListItem/TaskEditDialog/TaskEditDialog";
import { produce } from "immer";

interface PlacePanelProps {
  color?: string | Readable<string> | Readable<string | undefined>;
}

export function PlacePanel(props: PlacePanelProps, ctx: ViewContext) {
  const theme = ctx.getStore(ThemeStore);
  const nav = ctx.getStore(NavStore);
  const breakpoint = ctx.getStore(BreakpointStore);
  const router = ctx.getStore(RouterStore);
  const dialog = ctx.getStore(DialogStore);
  const projects = ctx.getStore(ProjectsStore);

  return (
    <div
      class={[styles.container, { [styles.withNav]: nav.$placeMenuIsOpen }]}
      style={theme.getThemeVariables$(props.color)}
    >
      <nav class={[styles.places, { [styles.open]: nav.$placeMenuIsOpen }]}>
        <button
          class={styles.placesShade}
          onClick={(e) => {
            e.preventDefault();
            nav.closePlaceMenu();
          }}
        />
        <div class={styles.placesMenu}>
          <NavMenu
            onNavigate={() => {
              if (breakpoint.$width.get() < 1200) {
                nav.closePlaceMenu();
              }
            }}
          />
        </div>
      </nav>

      <aside class={[styles.search, { [styles.open]: $(router.$$query, (q) => q.taskId != null) }]}>
        <button
          class={styles.searchShade}
          onClick={(e) => {
            e.preventDefault();
            router.$$query.update(
              produce((q) => {
                delete q.taskId;
              }),
            );
          }}
        />
        <div class={styles.searchMenu}>TASK</div>
      </aside>

      <aside class={[styles.search, { [styles.open]: nav.$searchIsOpen }]}>
        <button
          class={styles.searchShade}
          onClick={(e) => {
            e.preventDefault();
            nav.closeSearch();
          }}
        />
        <div class={styles.searchMenu}>
          <SearchMenu
            onSelected={(result) => {
              switch (result.type) {
                case "task":
                  router.navigate(`/projects/${result.data.projectId}/tasks`);
                  setTimeout(() => {
                    const project = projects.$cache.get().find((p) => p.id === result.data.projectId);
                    dialog.open(TaskEditDialog, {
                      projectId: result.data.projectId,
                      task: result.data,
                      color: project?.color,
                    });
                    nav.closeSearch();
                  }, 300);
                  break;
                case "note":
                  router.navigate(`/projects/${result.data.projectId}/notes/${result.data.id}`);
                  nav.closeSearch();
                  break;
                default:
                  nav.closeSearch();
                  break;
              }

              ctx.log("Selected", result);
            }}
          />
        </div>
      </aside>

      <div class={styles.content}>{ctx.outlet()}</div>
    </div>
  );
}
