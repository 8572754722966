export default {
  "container": "MoreMenu_container_58ad5",
  "small": "MoreMenu_small_58ad5",
  "button": "MoreMenu_button_58ad5",
  "card": "MoreMenu_card_58ad5",
  "open": "MoreMenu_open_58ad5",
  "icon": "MoreMenu_icon_58ad5",
  "menu": "MoreMenu_menu_58ad5",
  "listButton": "MoreMenu_listButton_58ad5",
  "destructive": "MoreMenu_destructive_58ad5"
};import "ni:sha-256;dtqmdOg1_FsQl_zomtM4Q-qhNYREAjZNKg17j1uZPJ0";