import { $, $$, LanguageStore, RouterStore, type ViewContext } from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import { SegmentButton } from "@views/SegmentButton";
import { Active } from "./Active/Active";
import { Assigned } from "./Assigned/Assigned";
import { Calendar } from "./Calendar/Calendar";
import styles from "./List.module.css";

export interface ListProps {}

export function List(props: ListProps, ctx: ViewContext) {
  ctx.name = "ProjectTasks/List";

  const auth = ctx.getStore(AuthStore);
  const router = ctx.getStore(RouterStore);
  const projects = ctx.getStore(ProjectsStore);
  const tasks = ctx.getStore(TasksStore);
  const { translate } = ctx.getStore(LanguageStore);

  const $projectId = $(router.$params, (params) => (params.projectId ? Number(params.projectId) : 0));

  const $project = $(projects.$cache, $projectId, (projects, id) => projects.find((p) => p.id === id));
  const $userRole = $(auth.$me, $project, (user, project) => {
    if (!user || !project || project.archivedAt != null) {
      return "viewer";
    }
    return project.users.find((u) => u.id === user.id)?.role ?? "viewer";
  });
  const $projectColor = $($project, (project) => project?.color);

  const $$selectedTab = $$<"active" | "assigned" | "calendar">("active");
  const $$filterTags = $$<string[]>([]);

  ctx.observe($projectId, (projectId) => {
    if (projectId != null && projectId != 0) {
      tasks.fetchIndexFor(projectId);
    }
  });

  return (
    <div class={styles.layout}>
      <div class={styles.segmentButtons}>
        <SegmentButton
          value={$$selectedTab}
          onChange={$$selectedTab.set}
          segments={[
            {
              value: "active",
              content: (
                <div class={styles.segment}>{translate("workspace.project.tasks.active.tabName")}</div>
              ),
            },
            {
              value: "assigned",
              content: (
                <div class={styles.segment}>{translate("workspace.project.tasks.assigned.tabName")}</div>
              ),
            },
            {
              value: "calendar",
              content: (
                <div class={styles.segment}>{translate("workspace.project.tasks.calendar.tabName")}</div>
              ),
            },
          ]}
        />
      </div>

      {$($$selectedTab, (tab) => {
        if (tab === "active") {
          return (
            <Active
              $projectId={$projectId}
              $userRole={$userRole}
              $projectColor={$projectColor}
              $$filterTags={$$filterTags}
            />
          );
        }

        if (tab === "assigned") {
          return (
            <Assigned
              $projectId={$projectId}
              $userRole={$userRole}
              $projectColor={$projectColor}
              $$filterTags={$$filterTags}
            />
          );
        }

        if (tab === "calendar") {
          return <Calendar $projectId={$projectId} $userRole={$userRole} />;
        }
      })}
    </div>
  );
}
