export default {
  "calendar": "CalendarView_calendar_b2edc",
  "header": "CalendarView_header_b2edc",
  "days": "CalendarView_days_b2edc",
  "day": "CalendarView_day_b2edc",
  "notInMonth": "CalendarView_notInMonth_b2edc",
  "isToday": "CalendarView_isToday_b2edc",
  "isSelected": "CalendarView_isSelected_b2edc",
  "dayButton": "CalendarView_dayButton_b2edc",
  "dayHeader": "CalendarView_dayHeader_b2edc",
  "dayMonthLabel": "CalendarView_dayMonthLabel_b2edc",
  "dayItems": "CalendarView_dayItems_b2edc",
  "dayItem": "CalendarView_dayItem_b2edc",
  "completed": "CalendarView_completed_b2edc",
  "hiddenMessage": "CalendarView_hiddenMessage_b2edc",
  "daySection": "CalendarView_daySection_b2edc",
  "empty": "CalendarView_empty_b2edc"
};import "ni:sha-256;jFX4oE9Nx4hF_MI5EjdSv3aUxL6nbJ253kejpe3qYsM";