import { type ViewContext } from "@manyducks.co/dolla";
import styles from "./Card.module.css";

export function CardToolbar(props: {}, ctx: ViewContext) {
  return (
    <div class={styles.cardToolbar} style={{ backgroundImage: "url(/textures/inspiration-geometry.png)" }}>
      <div class={styles.cardToolbarContent}>{ctx.outlet()}</div>
    </div>
  );
}
