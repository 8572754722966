import { Readable, ViewContext } from "@manyducks.co/dolla";
import styles from "./PlaceLayout.module.css";
import { ThemeStore } from "@stores/ThemeStore";

interface PlaceLayoutProps {
  color?: string | Readable<string> | Readable<string | undefined>;
}

export function PlaceLayout(props: PlaceLayoutProps, ctx: ViewContext) {
  const theme = ctx.getStore(ThemeStore);

  return (
    <section class={styles.layout} style={theme.getThemeVariables$(props.color)}>
      {ctx.outlet()}
    </section>
  );
}
