import { $, $$, HTTPStore, StoreContext } from "@manyducks.co/dolla";
import { Favorite, File } from "schemas";

export function NavStore(ctx: StoreContext) {
  const http = ctx.getStore(HTTPStore);

  /*=================================*\
  |*            Favorites            *|
  \*=================================*/

  const $$favorites = $$<Favorite[]>([]);

  async function fetchFavorites() {
    const res = await http.get<Favorite[]>("/api/favorites");
    $$favorites.set(res.body);
  }

  async function addFavorite(path: string) {
    let highestOrder = 0;
    for (const favorite of $$favorites.get()) {
      if (favorite.order > highestOrder) {
        highestOrder = favorite.order;
      }
    }
    const res = await http.post<Favorite>("/api/favorites", { body: { path } });
    $$favorites.update((current) => [...current, res.body]);
  }

  async function removeFavorite(id: number) {
    $$favorites.update((current) => current.filter((f) => f.id !== id));

    await http.delete(`/api/favorites/${id}`);
  }

  async function reorderFavorites(favorites: Favorite[]) {
    // TODO: Implement reordering
    localStorage.setItem("favorites", JSON.stringify($$favorites.get()));
  }

  /*=================================*\
  |*           File Viewer           *|
  \*=================================*/

  const $$fileViewerIsOpen = $$(false);
  const $$fileViewerContent = $$<File>();

  function openFileViewer(file: File) {
    $$fileViewerContent.set(file);
    $$fileViewerIsOpen.set(true);
  }

  function closeFileViewer() {
    $$fileViewerIsOpen.set(false);
  }

  /*=================================*\
  |*            Place Nav            *|
  \*=================================*/

  const $$placeMenuIsOpen = $$(localStorage.getItem("nav.placeMenuIsOpen") === "true");

  function openPlaceMenu() {
    $$placeMenuIsOpen.set(true);
  }

  function closePlaceMenu() {
    $$placeMenuIsOpen.set(false);
  }

  function togglePlaceMenu() {
    $$placeMenuIsOpen.update((open) => !open);
  }

  ctx.observe($$placeMenuIsOpen, (value) => {
    localStorage.setItem("nav.placeMenuIsOpen", value ? "true" : "false");
  });

  /*=================================*\
  |*             Search              *|
  \*=================================*/

  const $$searchIsOpen = $$(localStorage.getItem("nav.searchIsOpen") === "true");

  function openSearch() {
    $$searchIsOpen.set(true);
  }

  function closeSearch() {
    $$searchIsOpen.set(false);
  }

  function toggleSearch() {
    $$searchIsOpen.update((open) => !open);
  }

  ctx.observe($$searchIsOpen, (value) => {
    localStorage.setItem("nav.searchIsOpen", value ? "true" : "false");
  });

  return {
    $favorites: $($$favorites),
    fetchFavorites,
    addFavorite,
    removeFavorite,
    reorderFavorites,

    $fileViewerIsOpen: $($$fileViewerIsOpen, $$fileViewerContent, (open, content) => open && content != null),
    $fileViewerContent: $($$fileViewerContent),
    openFileViewer,
    closeFileViewer,

    $placeMenuIsOpen: $($$placeMenuIsOpen),
    openPlaceMenu,
    closePlaceMenu,
    togglePlaceMenu,

    $searchIsOpen: $($$searchIsOpen),
    openSearch,
    closeSearch,
    toggleSearch,
  };
}
