export default {
  "chat": "Chat_chat_a6667",
  "layout": "Chat_layout_a6667",
  "pushDisabledBanner": "Chat_pushDisabledBanner_a6667",
  "pushDisabledBannerControls": "Chat_pushDisabledBannerControls_a6667",
  "endOfMessages": "Chat_endOfMessages_a6667",
  "dividerLine": "Chat_dividerLine_a6667",
  "dividerLabel": "Chat_dividerLabel_a6667",
  "chatHeader": "Chat_chatHeader_a6667",
  "chatTitle": "Chat_chatTitle_a6667",
  "project": "Chat_project_a6667",
  "listContent": "Chat_listContent_a6667",
  "chatList": "Chat_chatList_a6667",
  "chatListItem": "Chat_chatListItem_a6667",
  "chatListItemIcon": "Chat_chatListItemIcon_a6667",
  "active": "Chat_active_a6667"
};import "ni:sha-256;GjVuOqiYqCX5lryZ-ZCieKfo5TgC_JUtPkS8qPMFrn4";