import { $, $$, DialogStore, LanguageStore, RouterStore, cond, type ViewContext } from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { BreakpointStore } from "@stores/BreakpointStore";
import { ChatStore } from "@stores/ChatStore";
import { IOStore } from "@stores/IOStore";
import { LoaderStore } from "@stores/LoaderStore";
import { NavStore } from "@stores/NavStore";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import { ThemeStore } from "@stores/ThemeStore";
import { FileViewer } from "@views/FileViewer";
import { Chat } from "./Chat";
import { PlacePanel } from "./PlacePanel/PlacePanel";
import styles from "./Workspace.module.css";

/**
 * Top level layout for the authenticated portions of the app.
 */
export function Workspace(_: {}, ctx: ViewContext) {
  const router = ctx.getStore(RouterStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);
  const io = ctx.getStore(IOStore);
  const auth = ctx.getStore(AuthStore);
  const projects = ctx.getStore(ProjectsStore);
  const loader = ctx.getStore(LoaderStore);
  const notes = ctx.getStore(NotesStore);
  const tasks = ctx.getStore(TasksStore);
  const chat = ctx.getStore(ChatStore);
  const breakpoint = ctx.getStore(BreakpointStore);
  const theme = ctx.getStore(ThemeStore);
  const dialog = ctx.getStore(DialogStore);
  const nav = ctx.getStore(NavStore);

  // 0 = menu, 1 = main, 2 = chat
  const $$screenPosition = $$(1);

  const $$menuWidth = $$(Number(localStorage.getItem("ui.menuWidth") ?? 300));
  const $$chatWidth = $$(Number(localStorage.getItem("ui.chatWidth") ?? 300));

  const $$resizeTarget = $$<"menu" | "chat" | null>(null);

  ctx.observe($$menuWidth, (menuWidth) => {
    localStorage.setItem("ui.menuWidth", String(menuWidth));
  });

  ctx.observe($$chatWidth, (chatWidth) => {
    localStorage.setItem("ui.chatWidth", String(chatWidth));
  });

  const $placeColor = $(
    router.$pattern,
    router.$params,
    projects.$cache,
    auth.$me,
    (pattern, params, projects, me) => {
      let color: string | undefined;

      if (pattern?.startsWith("/projects")) {
        const project = params.projectId
          ? projects.find((p) => p.id === Number(params.projectId))
          : undefined;

        color = project?.color ?? "#888";
      } else {
        color = me?.color ?? "#888";
      }

      return color;
    },
  );
  const $chatProject = $(chat.$$projectId, projects.$cache, (projectId, projects) => {
    return projects.find((p) => p.id === projectId);
  });

  ctx.observe($currentLanguage, (language) => {
    ctx.log({ language });
    if (language) {
      localStorage.setItem("quack-current-language", language);
    }
  });

  function onServiceWorkerMessage(this: ServiceWorkerContainer, event: MessageEvent<any>): any {
    ctx.info("received from worker", event.data);

    if (event.data.type === "chatMessage") {
      const projectId = event.data.project.id as number;
      chat.$$projectId.set(projectId);
      chat.open();
    }
  }

  const $$socketConnected = $$(false);
  const $$isLoaded = $$(false);

  ctx.beforeConnect(async () => {
    if (!auth.$isLoggedIn.get()) {
      router.navigate("/login");
      loader.hideAppLoader();
      return;
    }

    if (router.$path.get() === "/") {
      const lastVisited = localStorage.getItem("nav.lastVisitedPath");
      if (lastVisited) {
        router.navigate(lastVisited);
      } else {
        router.navigate("/my-tasks");
      }
    }

    ctx.observe(router.$path, (path) => {
      localStorage.setItem("nav.lastVisitedPath", path);
    });

    // Once user becomes logged out, drop them back at the landing page.
    ctx.observe(auth.$isLoggedIn, (isLoggedIn) => {
      if (!isLoggedIn) {
        router.navigate("/login");
      }
    });

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", onServiceWorkerMessage);
    }

    await auth.getUsers();

    const socket = io.socket();

    socket.on("connect", () => {
      auth.getUpdateStatus();
      $$socketConnected.set(true);
    });

    socket.on("disconnect", () => {
      $$socketConnected.set(false);
    });

    socket.on("task:update", tasks.taskUpdateReceived);
    socket.on("task:delete", tasks.taskDeleteReceived);

    socket.on("note:update", notes.noteUpdateReceived);
    socket.on("note:delete", notes.noteDeleteReceived);

    // socket.on("settings:update", auth.settingsUpdateReceived);

    socket.on("project:invited", projects.projectInviteReceived); // When a project invite is received
    socket.on("project:added", (projectId) => {}); // When user joins a project
    socket.on("project:update", projects.projectUpdateReceived);
    socket.on("project:delete", (projectId) => {
      projects.projectDeleteReceived(projectId);
      socket.emit("projects:refresh", () => {
        ctx.log("Projects refreshed");
      });
    });

    socket.on("user:update", auth.userUpdateReceived);

    socket.on("chat:message", chat.chatMessageReceived);

    await Promise.all([projects.refreshList(), chat.fetchMessages(), nav.fetchFavorites()]).then(() => {
      loader.hideAppLoader();
      $$isLoaded.set(true);
    });

    ctx.onDisconnected(() => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", onServiceWorkerMessage);
      }
      socket.close();
    });
  });

  ctx.observe($$socketConnected, async (socketConnected) => {
    if (!socketConnected || !$$isLoaded.get()) return;

    const promises: Promise<any>[] = [
      auth.getUsers(),
      projects.refreshList(),
      chat.fetchMessages(),
      nav.fetchFavorites(),
    ];

    // remove deleted resources
    const caches: {
      users: number[];
      projects: number[];
      notes: number[];
      tasks: number[];
    } = {
      users: auth.$users.get().map((x) => x.id),
      projects: projects.$cache.get().map((x) => x.id),
      notes: [],
      tasks: [],
    };

    const pattern = router.$pattern.get();
    const params = router.$params.get();

    const inNotes = pattern?.startsWith("/projects/{#projectId}/notes");
    const inTasks = pattern?.startsWith("/projects/{#projectId}/tasks");

    if (inNotes) {
      caches.notes = [...notes.$cache.get().keys()];
    } else if (inTasks) {
      caches.tasks = [...tasks.$cache.get().keys()];
    }

    const deletedCaches = await auth.validateCache(caches);

    auth.updateCache(deletedCaches.users);
    projects.updateCache(deletedCaches.projects);
    notes.updateCache(deletedCaches.notes);
    tasks.updateCache(deletedCaches.tasks);

    // load latest resources
    if (inNotes) {
      promises.push(notes.fetchIndexFor(Number(params.projectId), true));
    } else if (inTasks) {
      promises.push(tasks.fetchIndexFor(Number(params.projectId), true));
    } else if (pattern?.startsWith("/my-tasks")) {
      promises.push(tasks.fetchToDos());
    }

    await Promise.all(promises);

    ctx.log("Socket reconnected: data refreshed");
  });

  // check for updates
  const onVisibilityChange = async () => {
    if (document.visibilityState === "visible") {
      // clear notifications
      navigator.serviceWorker.ready.then((reg) => {
        reg.getNotifications().then((notifications) => {
          for (let i = 0; i < notifications.length; i += 1) {
            notifications[i].close();
          }
        });
      });

      // check for update
      const updateAvailable = await auth.getUpdateStatus();
      console.log(`UPDATE AVAILABLE: ${updateAvailable}`);
    }
  };

  ctx.onConnected(() => {
    window.addEventListener("visibilitychange", onVisibilityChange);
  });

  ctx.onDisconnected(() => {
    window.removeEventListener("visibilitychange", onVisibilityChange);
  });

  return cond(
    $$isLoaded,
    <div
      class={styles.workspace}
      // style={{
      //   // bottom: $($$bottomOffset, (offset) => `${offset}px`),
      //   height: $($$viewportHeight, (height) => `${height}px`),
      // }}
      onPointerMove={(e) => {
        const target = $$resizeTarget.get();
        if (!target) return;

        e.preventDefault();
        if (target === "menu") {
          $$menuWidth.set(Math.min(500, Math.max(240, e.clientX)));
        } else if (target === "chat") {
          $$chatWidth.set(Math.min(500, Math.max(300, window.innerWidth - e.clientX)));
        }
      }}
      onPointerUp={() => {
        $$resizeTarget.set(null);
      }}
    >
      <div class={styles.layout} style={{ backgroundImage: "url(/textures/inspiration-geometry.png)" }}>
        <div class={styles.layoutContent}>
          <PlacePanel color={$placeColor}>
            <div class={styles.main}>{ctx.outlet()}</div>
          </PlacePanel>

          <div
            class={[styles.chat, { [styles.open]: chat.$isOpen }]}
            style={theme.getThemeVariables$($($chatProject, (p) => p?.color ?? "#888"))}
          >
            <div
              class={styles.chatShade}
              onClick={() => {
                chat.close();
              }}
            />
            <div class={styles.chatContent}>
              <Chat
                onShowSettings={() => {
                  $$screenPosition.set(1);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {cond(nav.$fileViewerIsOpen, <FileViewer />)}
    </div>,
  );
}
