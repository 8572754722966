import { makeDebouncer } from "@helpers/makeDebouncer";
import { $, $$, LanguageStore, RouterStore, cond, repeat, type ViewContext } from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { ThemeStore } from "@stores/ThemeStore";
import SortAsc from "@views/@icons/SortAsc";
import SortDesc from "@views/@icons/SortDesc";
import { Button } from "@views/Button";
import { CardContent, CardToolbar } from "@views/Card";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { MoreMenu } from "@views/MoreMenu";
import { NoteListItem } from "@views/NoteListItem";
import { TagFilter } from "@views/TagFilter/TagFilter";
import { TextInput } from "@views/TextInput";
import { ToolBar } from "@views/ToolBar";
import { Note } from "schemas";
import styles from "./List.module.css";

interface ListProps {}

type NotesListOrder =
  | "updated-newest"
  | "updated-oldest"
  | "created-newest"
  | "created-oldest"
  | "alphabetical-newest"
  | "alphabetical-oldest";

export function List(props: ListProps, ctx: ViewContext) {
  ctx.name = "@cards/Notes/List";

  const auth = ctx.getStore(AuthStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);
  const router = ctx.getStore(RouterStore);
  const projects = ctx.getStore(ProjectsStore);
  const theme = ctx.getStore(ThemeStore);
  const notes = ctx.getStore(NotesStore);

  const $projectId = $(router.$params, (params) => Number(params.projectId));
  const $$orderBy = $$<NotesListOrder>("updated-newest");
  const $$filterTags = $$<string[]>([]);
  const $$collapsedSections = $$<string[]>([]);

  const $projectColor = $(
    $projectId,
    projects.$cache,
    (id, projects) => projects.find((p) => p.id === id)?.color ?? "#888",
  );

  const $tags = $($projectId, notes.$cache, (projectId, notes) => {
    const tags: { [tag: string]: number } = {};

    if (projectId != null) {
      for (const note of notes.values()) {
        if (note.projectId == projectId) {
          for (const tag of note.tags) {
            tags[tag] = (tags[tag] ?? 0) + 1;
          }
        }
      }
    }

    return tags;
  });

  const $project = $(projects.$cache, $projectId, (projects, id) => projects.find((p) => p.id === id));
  const $userRole = $($project, auth.$me, (project, me) => {
    if (!project || !me || project.archivedAt != null) return "viewer";
    return project.users.find((u) => u.id === me.id)?.role ?? "viewer";
  });
  const $canInteract = $($userRole, (role) => role !== "viewer");

  const $$scrollElement = $$<HTMLElement>();
  const scrollDebouncer = makeDebouncer(100);

  function onScroll(e: Event) {
    // const el = $$scrollElement.get()!;
    // scrollDebouncer.queue(() => {
    //   thisCard.updateState({
    //     listScrollPosition: el.scrollTop,
    //   });
    // });
  }

  ctx.onConnected(() => {
    const el = $$scrollElement.get()!;
    el.addEventListener("scroll", onScroll);
  });

  ctx.beforeDisconnect(() => {
    const el = $$scrollElement.get()!;
    el.removeEventListener("scroll", onScroll);
    scrollDebouncer.cancel();
    // thisCard.updateState({
    //   listScrollPosition: el.scrollTop,
    // });
  });

  const $$inputText = $$<string>("");

  const $notes = $(
    notes.$cache,
    $projectId,
    $$inputText,
    $$orderBy,
    $$filterTags,
    (notes, projectId, inputText, order, tags) => {
      const searchTerm = inputText.trim() === "" ? null : inputText.trim().toLowerCase();
      let results = [...notes.values()].filter(
        (n) => n.projectId === projectId && !n.deletedAt && tags.every((t) => n.tags.includes(t)),
      );

      if (searchTerm) {
        results = results.filter((r) => r.title.toLowerCase().includes(searchTerm));
      }

      return results.sort(makeSortByOrder(order));
    },
  );

  /**
   * Creates a writable that toggles a section open/closed.
   */
  function proxySectionIsOpen(section: string) {
    return $$($$collapsedSections, {
      get() {
        return !$$collapsedSections.get().includes(section);
      },
      set(open) {
        const current = $$collapsedSections.get();

        if (open && current.includes(section)) {
          $$collapsedSections.set(current.filter((x) => x !== section));
        }
        if (!open && !current.includes(section)) {
          $$collapsedSections.set([...current, section]);
        }
      },
    });
  }

  const $pinned = $($notes, (notes) => notes.filter((n) => n.isPinned));
  const $unpinned = $($notes, (notes) => [...notes.values()].filter((n) => !n.isPinned));

  const $$pinnedIsOpen = proxySectionIsOpen("pinned");
  const $$unpinnedIsOpen = proxySectionIsOpen("unpinned");
  const $$orderMenuOpen = $$(false);

  const getDraggedNote = (e: DragEvent, list = notes.$cache.get()) => {
    const data = e.dataTransfer!.getData("data/note-id");
    if (data) {
      const noteId = Number(data);
      return list.get(noteId);
    }
  };

  const onSortChange = (e: Event) => {
    const newValue = (e.currentTarget as HTMLOptionElement).value as any;
    $$orderBy.set(newValue);
  };

  const scrollToItem = (noteId: number) => {
    const scrollEl = $$scrollElement.get()!;
    const itemEl = scrollEl.querySelector(`[data-note-id='${noteId}']`);

    if (itemEl) {
      // Take measurements
      const scrollRect = scrollEl.getBoundingClientRect();
      const itemRect = itemEl?.getBoundingClientRect();

      // Calculate required movement to place item within viewport
      const boundsTop = scrollRect.top + 48;
      const boundsBottom = scrollRect.top + scrollRect.height - 8;

      // Perform movement
      if (itemRect.top < boundsTop) {
        // Scroll up
        ctx.log("MUST SCROLL UP BY", boundsTop - itemRect.top);
        scrollEl.scrollTo({
          top: scrollEl.scrollTop - (boundsTop - itemRect.top),
          behavior: "smooth",
        });
      } else if (itemRect.bottom > boundsBottom) {
        // Scroll down
        ctx.log("MUST SCROLL DOWN BY", itemRect.bottom - boundsBottom);
        scrollEl.scrollTo({
          top: scrollEl.scrollTop + (itemRect.bottom - boundsBottom),
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <div class={styles.container}>
      <div class={styles.filterSortGroup}>
        <div class={styles.tagFilter}>
          <TagFilter $suggestions={$tags} $$tags={$$filterTags} $color={$projectColor} />
        </div>
        <MoreMenu
          $$open={$$orderMenuOpen}
          icon={
            <div class={styles.orderIcon}>
              {$($$orderBy, (o) => (o.endsWith("-newest") ? <SortAsc /> : <SortDesc />))}
            </div>
          }
          title={$($$orderBy, $currentLanguage, (o) => {
            switch (o) {
              case "created-newest":
                return translate("workspace.project.notes.list.sortOrder.createdNewest").get();
              case "created-oldest":
                return translate("workspace.project.notes.list.sortOrder.createdOldest").get();
              case "updated-newest":
                return translate("workspace.project.notes.list.sortOrder.updatedNewest").get();
              case "updated-oldest":
                return translate("workspace.project.notes.list.sortOrder.updatedOldest").get();
              case "alphabetical-newest":
                return translate("workspace.project.notes.list.sortOrder.alphabeticalNewest").get();
              case "alphabetical-oldest":
                return translate("workspace.project.notes.list.sortOrder.alphabeticalOldest").get();
              default:
                return "";
            }
          })}
          color={$projectColor}
          preferHorizontalAlignment="left"
          preferVerticalAlignment="below"
          options={[
            {
              icon: <SortAsc />,
              label: translate("workspace.project.notes.list.sortOrder.createdNewest"),
              disabled: $($$orderBy, (o) => o === "created-newest"),
              callback: () => {
                $$orderBy.set("created-newest");
              },
            },
            {
              icon: <SortDesc />,
              label: translate("workspace.project.notes.list.sortOrder.createdOldest"),
              disabled: $($$orderBy, (o) => o === "created-oldest"),
              callback: () => {
                $$orderBy.set("created-oldest");
              },
            },
            {
              icon: <SortAsc />,
              label: translate("workspace.project.notes.list.sortOrder.updatedNewest"),
              disabled: $($$orderBy, (o) => o === "updated-newest"),
              callback: () => {
                $$orderBy.set("updated-newest");
              },
            },
            {
              icon: <SortDesc />,
              label: translate("workspace.project.notes.list.sortOrder.updatedOldest"),
              disabled: $($$orderBy, (o) => o === "updated-oldest"),
              callback: () => {
                $$orderBy.set("updated-oldest");
              },
            },
            {
              icon: <SortAsc />,
              label: translate("workspace.project.notes.list.sortOrder.alphabeticalNewest"),
              disabled: $($$orderBy, (o) => o === "alphabetical-newest"),
              callback: () => {
                $$orderBy.set("alphabetical-newest");
              },
            },
            {
              icon: <SortDesc />,
              label: translate("workspace.project.notes.list.sortOrder.alphabeticalOldest"),
              disabled: $($$orderBy, (o) => o === "alphabetical-oldest"),
              callback: () => {
                $$orderBy.set("alphabetical-oldest");
              },
            },
          ]}
        />
      </div>

      <CardContent ref={$$scrollElement}>
        <div class={styles.content}>
          {cond(
            $($notes, (x) => x.length === 0),

            // Show a message if there are no notes.
            <div class={styles.emptyMessage}>
              <span>
                {cond(
                  $($$inputText, (t) => t.trim().length > 0),
                  translate("workspace.project.notes.list.emptySearchResultsMessage"),
                  translate("workspace.project.notes.list.emptyListMessage"),
                )}
              </span>
            </div>,

            // Otherwise show pages.
            <>
              <CollapsibleListSection
                $$open={$$pinnedIsOpen}
                title={translate("workspace.project.notes.list.pinned.title")}
                itemCount={$($pinned, (x) => x.length)}
              >
                {cond(
                  $($pinned, (x) => x.length === 0),

                  <p class={styles.emptyMessage}>
                    {cond(
                      $($$inputText, (t) => t.trim().length > 0),
                      translate("workspace.project.notes.list.pinned.emptySearchResultsMessage"),
                      translate("workspace.project.notes.list.pinned.emptyListMessage"),
                    )}
                  </p>,

                  <ul class={styles.noteList}>
                    {repeat(
                      $pinned,
                      (note) => note.id,
                      ($note) => (
                        <NoteListItem
                          $note={$note}
                          $orderBy={$$orderBy}
                          scrollToItem={scrollToItem}
                          onClick={() => {
                            const note = $note.get();
                            router.navigate(`/projects/${note.projectId}/notes/${note.id}?state=view`);
                          }}
                          interactive={$canInteract}
                        />
                      ),
                    )}
                  </ul>,
                )}
              </CollapsibleListSection>

              <CollapsibleListSection
                $$open={$$unpinnedIsOpen}
                title={translate("workspace.project.notes.list.unpinned.title")}
                itemCount={$($unpinned, (x) => x.length)}
              >
                {cond(
                  $($unpinned, (x) => x.length === 0),

                  <p class={styles.emptyMessage}>
                    {cond(
                      $($$inputText, (t) => t.trim().length > 0),
                      translate("workspace.project.notes.list.unpinned.emptySearchResultsMessage"),
                      translate("workspace.project.notes.list.unpinned.emptyListMessage"),
                    )}
                  </p>,

                  <ul class={styles.noteList}>
                    {repeat(
                      $unpinned,
                      (note) => note.id,
                      ($note) => {
                        return (
                          <NoteListItem
                            $note={$note}
                            $orderBy={$$orderBy}
                            scrollToItem={scrollToItem}
                            onClick={() => {
                              const note = $note.get();
                              router.navigate(`/projects/${note.projectId}/notes/${note.id}?state=view`);
                            }}
                            interactive={$canInteract}
                          />
                        );
                      },
                    )}
                  </ul>,
                )}
              </CollapsibleListSection>
            </>,
          )}
        </div>
      </CardContent>

      <CardToolbar>
        <ToolBar>
          <form
            class={styles.searchOrCreate}
            onSubmit={(e) => {
              e.preventDefault();

              if (!$canInteract.get()) {
                return;
              }

              const projectId = $projectId.get();

              notes
                .createNote({
                  projectId: projectId,
                  delta: [
                    { insert: $$inputText.get() },
                    { insert: "\n", attributes: { header: 1 } },
                    { insert: "\n" },
                  ],
                  tags: $$filterTags.get(),
                })
                .then((note) => {
                  router.navigate(`/projects/${projectId}/notes/${note.id}?state=edit`);
                });

              $$inputText.set("");
            }}
          >
            <TextInput
              $$value={$$inputText}
              name="noteTitle"
              placeholder={translate("workspace.project.notes.list.searchOrCreate.inputPlaceholder")}
            />
            <Button
              type="submit"
              disabled={$(
                $$inputText,
                $canInteract,
                (text, interact) => !interact || text.trim().length === 0,
              )}
            >
              {translate("workspace.project.notes.list.searchOrCreate.buttonText")}
            </Button>
          </form>
        </ToolBar>
      </CardToolbar>
    </div>
  );
}

function makeSortByOrder(order: NotesListOrder) {
  return function (a: Note, b: Note) {
    switch (order) {
      case "created-newest":
        if (a.createdAt > b.createdAt) {
          return -1;
        } else if (a.createdAt < b.createdAt) {
          return 1;
        } else {
          return 0;
        }
      case "created-oldest":
        if (a.createdAt < b.createdAt) {
          return -1;
        } else if (a.createdAt > b.createdAt) {
          return 1;
        } else {
          return 0;
        }
      case "updated-newest":
        if (a.updatedAt > b.updatedAt) {
          return -1;
        } else if (a.updatedAt < b.updatedAt) {
          return 1;
        } else {
          return 0;
        }
      case "updated-oldest":
        if (a.updatedAt < b.updatedAt) {
          return -1;
        } else if (a.updatedAt > b.updatedAt) {
          return 1;
        } else {
          return 0;
        }
      case "alphabetical-newest":
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        } else {
          return 0;
        }
      case "alphabetical-oldest":
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        } else {
          return 0;
        }
      default:
        return 0;
    }
  };
}
