export default {
  "container": "Card_container_98173",
  "outOfViewport": "Card_outOfViewport_98173",
  "lifted": "Card_lifted_98173",
  "card": "Card_card_98173",
  "scope": "Card_scope_98173",
  "scopeIcon": "Card_scopeIcon_98173",
  "scopeLabel": "Card_scopeLabel_98173",
  "scopeRole": "Card_scopeRole_98173",
  "roleIcon": "Card_roleIcon_98173",
  "roleLabel": "Card_roleLabel_98173",
  "header": "Card_header_98173",
  "contacted": "Card_contacted_98173",
  "headerContent": "Card_headerContent_98173",
  "headerGroup": "Card_headerGroup_98173",
  "titleGroup": "Card_titleGroup_98173",
  "icon": "Card_icon_98173",
  "title": "Card_title_98173",
  "mainTitle": "Card_mainTitle_98173",
  "subtitle": "Card_subtitle_98173",
  "showSubtitle": "Card_showSubtitle_98173",
  "outlet": "Card_outlet_98173",
  "menuPixelIcon": "Card_menuPixelIcon_98173",
  "cardContent": "Card_cardContent_98173",
  "cardToolbar": "Card_cardToolbar_98173",
  "cardWidget": "Card_cardWidget_98173"
};import "ni:sha-256;0mYUikFnCdRZeI_jqva0GrtfNhHF_7V25zJrFGCX_DA";