export default {
  "layout": "NavMenu_layout_62ad1",
  "toolbar": "NavMenu_toolbar_62ad1",
  "content": "NavMenu_content_62ad1",
  "menuSection": "NavMenu_menuSection_62ad1",
  "menuHeader": "NavMenu_menuHeader_62ad1",
  "menuList": "NavMenu_menuList_62ad1",
  "projectListItem": "NavMenu_projectListItem_62ad1",
  "itemDivider": "NavMenu_itemDivider_62ad1",
  "project": "NavMenu_project_62ad1",
  "projectName": "NavMenu_projectName_62ad1",
  "projectInfo": "NavMenu_projectInfo_62ad1",
  "projectIcon": "NavMenu_projectIcon_62ad1",
  "addProjectSection": "NavMenu_addProjectSection_62ad1",
  "justifyEnd": "NavMenu_justifyEnd_62ad1",
  "toolButtonIcon": "NavMenu_toolButtonIcon_62ad1",
  "projectContent": "NavMenu_projectContent_62ad1",
  "projectMenu": "NavMenu_projectMenu_62ad1",
  "roleBadge": "NavMenu_roleBadge_62ad1",
  "projectMenuHeader": "NavMenu_projectMenuHeader_62ad1",
  "projectMenuHeaderText": "NavMenu_projectMenuHeaderText_62ad1",
  "projectMenuHeaderIcon": "NavMenu_projectMenuHeaderIcon_62ad1",
  "projectMenuMeta": "NavMenu_projectMenuMeta_62ad1",
  "navLink": "NavMenu_navLink_62ad1",
  "active": "NavMenu_active_62ad1",
  "navLinkIcon": "NavMenu_navLinkIcon_62ad1",
  "updateButton": "NavMenu_updateButton_62ad1",
  "updateButtonIcon": "NavMenu_updateButtonIcon_62ad1"
};import "ni:sha-256;VbY1dEycxnFF-xERWjljBJle8qqV5Vnb0Up9WNpuwag";