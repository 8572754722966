import {
  App,
  DialogStore,
  HTTPStore,
  LanguageStore,
  RouteMatchContext,
  RouterStore,
  ViewContext,
} from "@manyducks.co/dolla";

import "@styles/photoswipe.css";
import "@styles/quill.css";

import { enableMapSet } from "immer";

enableMapSet();

import { QuackLinkBlot } from "@views/TextEditor/blots/QuackLinkBlot";
import Quill from "quill";
import QuillCursors from "quill-cursors";
import MagicUrl from "quill-magic-url";

import registerServiceWorker from "./registerServiceWorker";

import { AuthStore } from "@stores/AuthStore";
import { BadgeStore } from "@stores/BadgeStore";
import { BreakpointStore } from "@stores/BreakpointStore";
import { ChatStore } from "@stores/ChatStore";
import { ClockStore } from "@stores/ClockStore";
import { FilesStore } from "@stores/FilesStore";
import { IOStore } from "@stores/IOStore";
import { LoaderStore } from "@stores/LoaderStore";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { PushStore } from "@stores/PushStore";
import { TasksStore } from "@stores/TasksStore";
import { ThemeStore } from "@stores/ThemeStore";

import { Join } from "Join";
import { Login } from "Login";

import {
  MyTasks,
  Settings,
  Admin,
  AdminServerStats,
  AdminPeople,
  AdminProjects,
  Project,
  ProjectNotes,
  ProjectOverview,
  ProjectTasks,
  Workspace,
} from "Workspace";

import { NavStore } from "@stores/NavStore";

// @ts-ignore | default does exist on MagicUrl
Quill.register("modules/magicUrl", MagicUrl.default);
Quill.register("modules/cursors", QuillCursors);
Quill.register(`blots/${QuackLinkBlot.blotName}`, QuackLinkBlot);

const IS_DEVELOPMENT = process.env.NODE_ENV === "development";

async function beforeMatch(ctx: RouteMatchContext) {
  return ctx.getStore(LoaderStore).showAppLoader();
}

export const app = App({
  stores: [
    { store: HTTPStore },
    { store: DialogStore },
    {
      store: LanguageStore,
      options: {
        defaultLanguage: localStorage.getItem("quack-current-language"),
        languages: [
          { name: "en", translations: "/locales/en.json" },
          { name: "ja", translations: "/locales/ja.json" },
          { name: "tok", translations: "/locales/tok.json" },
        ],
      },
    },
    {
      store: RouterStore,
      options: {
        routes: [
          {
            path: "/login",
            view: Login,
            beforeMatch: (ctx: RouteMatchContext) => {
              ctx.getStore(LoaderStore).hideAppLoader();
            },
          },
          { path: "/join/*", view: Join, beforeMatch },
          {
            path: "/",
            view: Workspace,
            beforeMatch,
            routes: [
              {
                path: "/my-tasks",
                view: MyTasks,
                beforeMatch: (ctx: RouteMatchContext) => {
                  ctx.getStore(LoaderStore).hideAppLoader();
                },
              },
              {
                path: "/settings",
                view: Settings,
                beforeMatch: (ctx: RouteMatchContext) => {
                  ctx.getStore(LoaderStore).hideAppLoader();
                },
              },
              // {
              //   path: "/people/{#userId}",
              //   view: People,
              //   beforeMatch: (ctx: RouteMatchContext) => {
              //     ctx.getStore(LoaderStore).hideAppLoader();
              //   },
              // },
              {
                path: "/admin",
                view: Admin,
                beforeMatch: (ctx: RouteMatchContext) => {
                  ctx.getStore(LoaderStore).hideAppLoader();
                },
                routes: [
                  { path: "/server-stats", view: AdminServerStats },
                  { path: "/people", view: AdminPeople },
                  { path: "/projects", view: AdminProjects },
                  { path: "*", redirect: "./server-stats" },
                ],
              },
              {
                path: "/projects/{#projectId}",
                view: Project,
                beforeMatch: (ctx: RouteMatchContext) => {
                  ctx.getStore(LoaderStore).hideAppLoader();
                },
                routes: [
                  {
                    path: "/",
                    view: ProjectOverview,
                  },
                  {
                    path: "/notes",
                    view: ProjectNotes,
                    routes: [
                      { path: "/{#noteId}", view: () => <h1>Note Details</h1> },
                      { path: "/", view: () => <h1>No Note</h1> },
                      { path: "*", redirect: "./" },
                    ],
                  },
                  {
                    path: "/tasks",
                    view: ProjectTasks,
                    routes: [
                      { path: "/", view: () => <h1>To Do</h1> },
                      { path: "/assigned", view: () => <h1>Assigned</h1> },
                      { path: "/calendar/*", view: () => <h1>Calendar</h1> },
                      { path: "*", redirect: "./" },
                    ],
                  },
                ],
              },
              {
                path: "/p/{linkingId}",
                view: (props: {}, ctx: ViewContext) => {
                  ctx.name = "Permalink route";

                  const router = ctx.getStore(RouterStore);
                  ctx.beforeConnect(async () => {
                    const { linkingId } = router.$params.get();

                    // TODO: Look up and redirect to path
                    ctx.log(`TODO: Look up permalink with linking id: ${linkingId}`);

                    if (true) {
                      router.navigate("/my-tasks");
                    }
                  });

                  return <h1>Linking...</h1>;
                },
              },
              { path: "/", view: () => <h1>Redirecting...</h1> },
              { path: "*", redirect: "./" },
            ],
          },
          { path: "*", redirect: "/login" },
        ],
      },
    },
    { store: BadgeStore },
    { store: PushStore },
    { store: AuthStore },
    { store: BreakpointStore },
    { store: NavStore },
    { store: LoaderStore },
    { store: ThemeStore },
    { store: ClockStore },
    { store: ProjectsStore },
    { store: NotesStore },
    { store: FilesStore },
    { store: IOStore },
    { store: TasksStore },
    { store: ChatStore },
  ],

  debug: {
    filter: IS_DEVELOPMENT ? "*,-dolla/http,-dolla/render" : "-*",
  },
  mode: IS_DEVELOPMENT ? "development" : "production",
});

/*=========================*\
||   Configure & Connect   ||
\*=========================*/

app.configure(async (ctx) => {
  if (IS_DEVELOPMENT) {
    console.warn(`Quack is running in development mode.`);
  }

  await ctx.getStore(LanguageStore).loaded;
  await registerServiceWorker();
});

app.connect("#app");

// Prevent drag and drops on window redirecting the page to that file.
window.addEventListener(
  "dragover",
  (e) => {
    e.preventDefault();
  },
  false,
);
window.addEventListener(
  "drop",
  (e) => {
    e.preventDefault();
  },
  false,
);
