import { $, $$, type Readable, type StoreContext } from "@manyducks.co/dolla";
import { adjustAccentColor, makeTheme, makeThemeVariables } from "@helpers/makeTheme";
import { AuthStore } from "./AuthStore";

export enum ColorSchemes {
  System = "system",
  Light = "light",
  Dark = "dark",
}

export type Theme = ReturnType<typeof makeTheme>;
export type ThemeVariables = ReturnType<typeof makeThemeVariables>;

type ThemeSet = {
  light: Theme;
  dark: Theme;
  lightVars: ThemeVariables;
  darkVars: ThemeVariables;
};

// Store exactly one copy of each generated theme.
const themeCache = new Map<string, ThemeSet>();

function getThemeSet(hex: string): ThemeSet {
  const cached = themeCache.get(hex);

  if (cached) {
    return cached;
  } else {
    const light = makeTheme(hex, false);
    const dark = makeTheme(hex, true);
    const theme = {
      light,
      dark,
      lightVars: makeThemeVariables(light),
      darkVars: makeThemeVariables(dark),
    };
    themeCache.set(hex, theme);
    return theme;
  }
}

export function ThemeStore(ctx: StoreContext) {
  const auth = ctx.getStore(AuthStore);

  const $$systemScheme = $$<ColorSchemes>(ColorSchemes.Light);
  const $$selectedScheme = $$<ColorSchemes>(ColorSchemes.System);

  const $defaultColor = $("#2D79E9");

  const $currentScheme = $($$systemScheme, $$selectedScheme, (system, selected) => {
    if (
      selected === ColorSchemes.Dark ||
      (selected === ColorSchemes.System && system === ColorSchemes.Dark)
    ) {
      return ColorSchemes.Dark;
    } else {
      return ColorSchemes.Light;
    }
  });

  const $cssVars = $($currentScheme, $defaultColor, (scheme, defaultColor) => {
    const theme = getThemeSet(defaultColor);
    if (scheme === ColorSchemes.Dark) {
      return theme.darkVars;
    } else {
      return theme.lightVars;
    }
  });

  const $userThemeSet = $(auth.$me, $defaultColor, (me, defaultColor) => {
    return getThemeSet(me?.color ?? defaultColor);
  });
  const $userTheme = $($userThemeSet, $currentScheme, (theme, scheme) => {
    if (scheme === ColorSchemes.Dark) {
      return theme.dark;
    } else {
      return theme.light;
    }
  });
  const $userThemeVars = $($userThemeSet, $currentScheme, (theme, scheme) => {
    if (scheme === ColorSchemes.Dark) {
      return theme.darkVars;
    } else {
      return theme.lightVars;
    }
  });

  // Adds CSS vars to document body when they change.
  ctx.observe($userThemeVars, (vars) => {
    Object.keys(vars).forEach((key) => {
      document.body.style.setProperty(key, (vars as any)[key]);
    });

    // Update iOS status bar color.
    document.querySelector('meta[name="theme-color"]')?.setAttribute("content", vars["--color-accent-1"]);
  });

  // TODO: Add queueUpdate to store context (they can manipulate the DOM)
  ctx.observe($userTheme, (theme) => {
    document.body.style.setProperty("--color-user-accent", theme.accent[0]);
    document.body.style.setProperty("--color-user-accent-contrast", theme.accentContrast);
    document.body.style.setProperty("--color-user-accent-1", theme.accent[1]);
    document.body.style.setProperty("--color-user-accent-2", theme.accent[2]);
    document.body.style.setProperty("--color-user-accent-3", theme.accent[3]);
    document.body.style.setProperty("--color-user-accent-4", theme.accent[4]);
    document.body.style.setProperty("--color-user-accent-5", theme.accent[5]);

    const lightThemeAccent = document.querySelector("#light-theme-accent") as HTMLMetaElement;
    if (lightThemeAccent) lightThemeAccent.content = theme.accent[1];

    const darkThemeAccent = document.querySelector("#dark-theme-accent") as HTMLMetaElement;
    if (darkThemeAccent) lightThemeAccent.content = theme.accent[1];
  });

  ctx.observe($currentScheme, (scheme) => {
    if (scheme === ColorSchemes.Dark) {
      document.body.classList.remove("color-scheme-light");
      document.body.classList.add("color-scheme-dark");
    } else {
      document.body.classList.add("color-scheme-light");
      document.body.classList.remove("color-scheme-dark");
    }
  });

  ctx.onConnected(async () => {
    const stored = localStorage.getItem("quack-color-scheme") as ColorSchemes;

    if (stored && Object.values(ColorSchemes).includes(stored)) {
      $$selectedScheme.set(stored);
    } else {
      $$selectedScheme.set(ColorSchemes.System);
    }

    // Persist the selected scheme to local storage.
    ctx.observe($$selectedScheme, (scheme) => {
      localStorage.setItem("quack-color-scheme", scheme);
    });

    // Watch the system color scheme for changes.
    const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");

    // Set system scheme to match the current OS setting.
    $$systemScheme.set(darkModeQuery.matches ? ColorSchemes.Dark : ColorSchemes.Light);

    // Update the system scheme when it changes.
    darkModeQuery.addEventListener("change", (e) => {
      $$systemScheme.set(e.matches ? ColorSchemes.Dark : ColorSchemes.Light);
    });
  });

  /**
   * Returns a theme build around `color` as a set of CSS variables. Automatically adapts for light and dark mode.
   */
  function getThemeVariables$(color?: string | Readable<string> | Readable<string | undefined>) {
    return $(color, $defaultColor, $currentScheme, (c, defaultColor, scheme) => {
      const theme = getThemeSet(c ?? defaultColor);
      if (scheme === ColorSchemes.Dark) {
        return theme.darkVars;
      } else {
        return theme.lightVars;
      }
    });
  }

  function getAdjustedColor$(color?: string | Readable<string> | Readable<string | undefined>) {
    return $(color, $defaultColor, $currentScheme, (c, defaultColor, scheme) => {
      return adjustAccentColor(c ?? defaultColor, scheme === ColorSchemes.Dark);
    });
  }

  return {
    ColorSchemes,
    $$selectedScheme,
    $currentScheme,
    $cssVars,
    $isDark: $($currentScheme, (scheme) => scheme === ColorSchemes.Dark),
    $userTheme,
    $userThemeVars,

    getThemeVariables$,
    getAdjustedColor$,
  };
}
