export default {
  "container": "Details_container_f5b43",
  "loader": "Details_loader_f5b43",
  "show": "Details_show_f5b43",
  "meta": "Details_meta_f5b43",
  "metaButton": "Details_metaButton_f5b43",
  "active": "Details_active_f5b43",
  "open": "Details_open_f5b43",
  "metaButtonIcon": "Details_metaButtonIcon_f5b43",
  "metaButtonLabel": "Details_metaButtonLabel_f5b43",
  "metaContent": "Details_metaContent_f5b43",
  "metaSection": "Details_metaSection_f5b43",
  "metaSubSection": "Details_metaSubSection_f5b43",
  "metaAttachmentsList": "Details_metaAttachmentsList_f5b43",
  "metaAttachmentUpload": "Details_metaAttachmentUpload_f5b43",
  "metaAttachmentUploadInput": "Details_metaAttachmentUploadInput_f5b43",
  "metaAttachmentUploadLabel": "Details_metaAttachmentUploadLabel_f5b43",
  "outlineList": "Details_outlineList_f5b43",
  "outlineItem": "Details_outlineItem_f5b43",
  "metaCheckbox": "Details_metaCheckbox_f5b43",
  "metaCheckboxLabel": "Details_metaCheckboxLabel_f5b43",
  "timestampList": "Details_timestampList_f5b43",
  "timestampListItem": "Details_timestampListItem_f5b43",
  "timestampListItemMain": "Details_timestampListItemMain_f5b43",
  "timestampListItemSecondary": "Details_timestampListItemSecondary_f5b43",
  "content": "Details_content_f5b43",
  "editToggleGroup": "Details_editToggleGroup_f5b43"
};import "ni:sha-256;EZXzKyAG37QVmP1qdYf0zx8qxU3tTHLUz9cXwOhcreI";