export default {
  "container": "TextEditor_container_b8902",
  "toolbar": "TextEditor_toolbar_b8902",
  "hidden": "TextEditor_hidden_b8902",
  "toolbarGroup": "TextEditor_toolbarGroup_b8902",
  "toolbarButton": "TextEditor_toolbarButton_b8902",
  "active": "TextEditor_active_b8902",
  "toolbarDivider": "TextEditor_toolbarDivider_b8902",
  "editor": "TextEditor_editor_b8902",
  "searchMenu": "TextEditor_searchMenu_b8902",
  "linkBlot": "TextEditor_linkBlot_b8902",
  "linkBlotIcon": "TextEditor_linkBlotIcon_b8902",
  "linkBlotTaskStage": "TextEditor_linkBlotTaskStage_b8902",
  "linkBlotTitle": "TextEditor_linkBlotTitle_b8902"
};import "ni:sha-256;2FkU6rlSytKisd4SD09kNwR9zid5WMmpXJrgjd9zAtc";