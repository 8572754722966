import { $, LanguageStore, ViewContext } from "@manyducks.co/dolla";
import { TabBar } from "@views/TabBar";
import styles from "./Admin.module.css";
import { AuthStore } from "@stores/AuthStore";
import { PlaceLayout } from "../PlaceLayout/PlaceLayout";
import { PlaceHeader } from "../PlaceHeader/PlaceHeader";

export function Admin(props: {}, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);
  const auth = ctx.getStore(AuthStore);

  return (
    <PlaceLayout color={$(auth.$me, (me) => me?.color)}>
      <PlaceHeader
        $title={translate("workspace.nav.admin.label")}
        $icon={$(
          <img
            alt=""
            src="/icons/rooms.12.trans.png"
            style={{ width: "24px", height: "24px", imageRendering: "pixelated" }}
          />,
        )}
        tabs={[
          {
            href: "/admin/server-stats",
            active: (pattern) => pattern.startsWith("/admin/server-stats"),
            text: translate("workspace.admin.serverStats.tabName"),
          },
          {
            href: "/admin/people",
            active: (pattern) => pattern.startsWith("/admin/people"),
            text: translate("workspace.admin.people.tabName"),
          },
          {
            href: "/admin/projects",
            active: (pattern) => pattern.startsWith("/admin/projects"),
            text: translate("workspace.admin.projects.tabName"),
          },
        ]}
      />

      <div class={styles.content}>{ctx.outlet()}</div>
    </PlaceLayout>
  );
}
