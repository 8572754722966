export default {
  "layout": "SearchMenu_layout_89c59",
  "textInput": "SearchMenu_textInput_89c59",
  "formatOptions": "SearchMenu_formatOptions_89c59",
  "formatOption": "SearchMenu_formatOption_89c59",
  "formatIcon": "SearchMenu_formatIcon_89c59",
  "results": "SearchMenu_results_89c59",
  "resultContent": "SearchMenu_resultContent_89c59",
  "noResults": "SearchMenu_noResults_89c59",
  "userScopeCharm": "SearchMenu_userScopeCharm_89c59"
};import "ni:sha-256;zCnxEEJOZ-JaI-olZf-QGp1fsa5B04M6pWQFheZvul4";