import { $, LanguageStore, RouterStore, ViewContext } from "@manyducks.co/dolla";
import styles from "./Project.module.css";
import { ProjectsStore } from "@stores/ProjectsStore";
import { ThemeStore } from "@stores/ThemeStore";
import { TabBar } from "@views/TabBar";
import { PlaceLayout } from "Workspace/PlaceLayout/PlaceLayout";
import { PlaceHeader } from "Workspace/PlaceHeader/PlaceHeader";

export function Project(props: {}, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);
  const router = ctx.getStore(RouterStore);
  const projects = ctx.getStore(ProjectsStore);
  const $projectId = $(router.$params, (params) => (params.projectId ? Number(params.projectId) : null));
  const $project = $($projectId, projects.$cache, (id, projects) => projects.find((p) => p.id === id));

  ctx.onConnected(() => {
    ctx.observe($projectId, async (projectId) => {
      if (projectId != null) {
        const { exists } = await projects.ensureProjectLoaded(projectId);
        if (!exists) {
          return router.navigate("/");
        }
      }
    });
  });

  return (
    <PlaceLayout color={$($project, (p) => p?.color)}>
      <PlaceHeader
        $title={$($project, (p) => p?.name ?? "???")}
        $icon={
          <img
            src="/icons/projects.12.trans.png"
            alt=""
            style={{ width: "24px", height: "24px", imageRendering: "pixelated" }}
          />
        }
        tabs={[
          {
            href: $($projectId, (id) => `/projects/${id}`),
            active: (pattern) => pattern === "/projects/{#projectId}",
            text: translate("workspace.project.overview.tabName"),
          },
          {
            href: $($projectId, (id) => `/projects/${id}/notes`),
            active: (pattern) => pattern.startsWith("/projects/{#projectId}/notes"),
            hidden: $($project, (p) => !p?.notesEnabled),
            text: translate("workspace.project.notes.tabName"),
          },
          {
            href: $($projectId, (id) => `/projects/${id}/tasks`),
            active: (pattern) => pattern.startsWith("/projects/{#projectId}/tasks"),
            hidden: $($project, (p) => !p?.tasksEnabled),
            text: translate("workspace.project.tasks.tabName"),
          },
        ]}
      />

      <div class={styles.content}>{ctx.outlet()}</div>
    </PlaceLayout>
  );
}
