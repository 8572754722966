export default {
  "content": "Settings_content_23314",
  "formToolBar": "Settings_formToolBar_23314",
  "formGroup": "Settings_formGroup_23314",
  "userName": "Settings_userName_23314",
  "nameForm": "Settings_nameForm_23314",
  "nameFormButtons": "Settings_nameFormButtons_23314",
  "userStatus": "Settings_userStatus_23314",
  "userAvatar": "Settings_userAvatar_23314",
  "userColorInput": "Settings_userColorInput_23314",
  "userColorButtons": "Settings_userColorButtons_23314",
  "editor": "Settings_editor_23314",
  "editorButtons": "Settings_editorButtons_23314",
  "formLabel": "Settings_formLabel_23314",
  "sectionHeader": "Settings_sectionHeader_23314",
  "headerTitle": "Settings_headerTitle_23314",
  "sectionList": "Settings_sectionList_23314",
  "settingsButton": "Settings_settingsButton_23314",
  "flowVertical": "Settings_flowVertical_23314",
  "statusLabel": "Settings_statusLabel_23314",
  "toggleGroup": "Settings_toggleGroup_23314",
  "colorSchemeOption": "Settings_colorSchemeOption_23314"
};import "ni:sha-256;OCOv6I0OyT1wki4I8hPl9-dmglJkxBMw9YGP5d6uNAM";