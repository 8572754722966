export default {
  "container": "PlacePanel_container_6b22f",
  "places": "PlacePanel_places_6b22f",
  "placesShade": "PlacePanel_placesShade_6b22f",
  "placesMenu": "PlacePanel_placesMenu_6b22f",
  "open": "PlacePanel_open_6b22f",
  "search": "PlacePanel_search_6b22f",
  "searchShade": "PlacePanel_searchShade_6b22f",
  "searchMenu": "PlacePanel_searchMenu_6b22f",
  "content": "PlacePanel_content_6b22f",
  "withNav": "PlacePanel_withNav_6b22f"
};import "ni:sha-256;hSJEPdlFDi2B9EORY8xZienu1F_OcveWJFzZlp0no9k";