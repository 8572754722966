import { $, $$, HTTPStore, RouterStore, type StoreContext } from "@manyducks.co/dolla";
import { produce } from "immer";
import { DeltaOperation } from "quill";
import { type ChatMessage } from "schemas";
import { IOStore } from "./IOStore";
import { ProjectsStore } from "./ProjectsStore";

function loadProjectId() {
  const stored = localStorage.getItem("chat.projectId");
  return stored ? Number(stored) : undefined;
}

export function ChatStore(ctx: StoreContext) {
  const http = ctx.getStore(HTTPStore);
  const io = ctx.getStore(IOStore);
  const projects = ctx.getStore(ProjectsStore);

  const $$isOpen = $$(localStorage.getItem("chat.isOpen") == "true" ? true : false);

  function open() {
    $$isOpen.set(true);
  }

  function close() {
    $$isOpen.set(false);
  }

  function toggle() {
    $$isOpen.update((open) => !open);
  }

  ctx.observe($$isOpen, (open) => {
    localStorage.setItem("chat.isOpen", open ? "true" : "false");
  });

  const $$messages = $$<ChatMessage[]>([]);
  const $$projectId = $$<number | undefined>(loadProjectId());

  ctx.observe($$projectId, (projectId) => {
    if (projectId == null) {
      localStorage.removeItem("chat.projectId");
    } else {
      localStorage.setItem("chat.projectId", String(projectId));
    }
  });

  // Deselect project if chat is not enabled.
  ctx.observe(projects.$cache, $$projectId, (projects, id) => {
    const project = projects.find((p) => p.id === id);
    if (project == null) return;
    if (project.archivedAt != null || !project.chatEnabled) {
      $$projectId.set(undefined);
    }
  });

  async function fetchMessages() {
    const res = await http.get<ChatMessage[]>(`/api/chat`);
    $$messages.set(res.body);
  }

  /**
   * @param delta - Quill delta of message content
   * @param attachments - UUIDs of uploaded files to attach
   */
  async function sendMessage(projectId: number, delta: DeltaOperation[], attachments: string[]) {
    const socket = io.socket();
    socket.emit("chat:message", { projectId, delta, attachments });
  }

  return {
    $isOpen: $($$isOpen),
    open,
    close,
    toggle,

    $messages: $($$messages),
    $$projectId,
    fetchMessages,
    sendMessage,

    chatMessageReceived: (message: ChatMessage) => {
      ctx.log("received chat message", message);

      $$messages.update(
        produce((messages) => {
          const found = messages.find((m) => m.id === message.id);
          if (found) {
            Object.assign(found, message);
          } else {
            messages.push(message);
          }
        }),
      );
    },
  };
}
