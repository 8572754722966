import { EventEmitter } from "@borf/bedrock";
import { $, $$, cond, LanguageStore, repeat, type Readable, type ViewContext } from "@manyducks.co/dolla";
import { FilesStore, type UploadEvents } from "@stores/FilesStore";
import scrollStyles from "@styles/ScrollBar.module.css";
import CloseIcon from "@views/@icons/Close";
import { Button } from "@views/Button";
import { HoverMenu, Point } from "@views/HoverMenu";
import { IconButton } from "@views/IconButton";
import { MoreMenu } from "@views/MoreMenu";
import { SearchMenu } from "@views/SearchMenu";
import markdownStyles from "@views/TextEditor/Markdown.module.css";
import { QuackLinkBlot } from "@views/TextEditor/blots/QuackLinkBlot";
import Link from "@views/TextEditor/icons/Link";
import { produce } from "immer";
import prettyBytes from "pretty-bytes";
import Quill, { DeltaOperation, type RangeStatic } from "quill";
import { QuillBinding } from "y-quill";
import * as Y from "yjs";
import styles from "./ChatEditor.module.css";
import { promptForFiles } from "@helpers/promptForFiles";
import Paperclip from "@views/@icons/Paperclip";
import Plus from "@views/@icons/Plus";
import { AuthStore } from "@stores/AuthStore";
import { ThemeStore } from "@stores/ThemeStore";

export interface InputFile {
  uploadId: string;
  file: File;
  progress: number;
  complete: boolean;
  events: EventEmitter<UploadEvents>;
  error?: Error;
}

interface ChatEditorProps {
  onSubmit: (delta: DeltaOperation[], attachments: string[]) => void;
}

/**
 * Provides a UI for creating chat messages, including file uploads and message formatting.
 */
export function ChatEditor(props: ChatEditorProps, ctx: ViewContext) {
  const auth = ctx.getStore(AuthStore);
  const theme = ctx.getStore(ThemeStore);
  const { translate } = ctx.getStore(LanguageStore);
  const { uploadFile } = ctx.getStore(FilesStore);

  const $placeholder = translate("workspace.chat.inputBox.inputPlaceholder");
  const $$editorElement = $$<HTMLDivElement>();

  const ydoc = new Y.Doc();
  const inputValue = ydoc.getText();

  const $$showPlaceholder = $$(true);
  const $$inputHasContent = $$(inputValue.length > 0);
  const $$inputFiles = $$<InputFile[]>([]);

  const $userColor = $(auth.$me, (user) => user?.color ?? "#888");

  ydoc.on("update", () => {
    const value = inputValue.toString().trim();
    $$inputHasContent.set(value.length > 0);
    $$showPlaceholder.set(inputValue.toDelta().length === 0);
  });

  const $canSend = $($$inputFiles, $$inputHasContent, (files, hasContent) => {
    if (files.length === 0) {
      // Don't allow sending an empty message with no files.
      if (!hasContent) {
        return false;
      }
    } else {
      // Don't allow sending when some files are still uploading.
      if (files.find((f) => !f.complete) || files.find((f) => f.error != null)) {
        return false;
      }
    }
    return true;
  });

  ctx.observe($canSend, (value) => {
    ctx.log("canSend", value);
  });

  let editor: Quill;
  let binding: QuillBinding;

  const $$linkMenuIsOpen = $$(false);
  const $$linkMenuAnchor = $$<Point>({ x: 0, y: 0 });
  const $$linkSelection = $$<RangeStatic | null>(null);

  const $$attachmentMenuIsOpen = $$(false);

  ctx.onConnected(() => {
    editor = new Quill($$editorElement.get()!, {
      formats: ["bold", "italic", "link", "list", "blockquote", "image", "indent", "code-block", "quackLink"],
      modules: {
        magicUrl: true,
        keyboard: {
          bindings: {
            shift_enter: {
              key: [13, "Enter"],
              shiftKey: true,
              handler: (range: any, ctx: any) => {
                editor.insertText(range.index, "\n");
              },
            },
            enter: {
              key: [13, "Enter"],
              handler: (range: any, ctx: any) => {
                if ($canSend.get()) {
                  onSend();
                }
              }, // submit form
            },
          },
        },
      },
    });

    binding = new QuillBinding(inputValue, editor);
  });

  ctx.onDisconnected(() => {
    binding.destroy();
  });

  function onSend() {
    if ($canSend.get()) {
      const attachments = $$inputFiles.get().map((f) => f.uploadId);
      const delta = inputValue.toDelta();

      props.onSubmit(delta, attachments);

      inputValue.delete(0, inputValue.length);
      $$inputFiles.set([]);
    }
  }

  function addFiles(files: FileList | File[]) {
    for (const file of Array.from(files)) {
      const events = uploadFile(file);
      let uploadId: string;

      events.on("started", (e) => {
        ctx.log("upload started", e);
        uploadId = e.data.uploadId;
        const inputFile: InputFile = {
          file,
          uploadId,
          progress: 0,
          complete: false,
          events,
        };
        $$inputFiles.update(
          produce((current) => {
            current.push(inputFile);
          }),
        );
      });
      events.on("progress", (e) => {
        ctx.log("upload progress", e);
        $$inputFiles.update(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.progress = e.data.percent;
            }
          }),
        );
      });
      events.on("error", (e) => {
        ctx.log("upload error", e);
        $$inputFiles.update(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.error = e.data.error;
            }
          }),
        );
      });
      events.on("complete", (e) => {
        ctx.log("upload complete", e);
        $$inputFiles.update(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.complete = true;
            }
          }),
        );
      });
    }
  }

  return (
    <div
      class={styles.container}
      style={theme.getThemeVariables$($userColor)}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ul class={styles.attachments}>
        {repeat(
          $$inputFiles,
          (f) => f.uploadId,
          ($file) => (
            <InputAttachment
              $file={$file}
              onRemove={(file) => {
                // Abort upload and rely on nightly cleanup to remove abandoned chunks and records.
                file.events.emit("abort!", null);

                $$inputFiles.update((files) => {
                  return files.filter((f) => f.uploadId !== file.uploadId);
                });
              }}
            />
          ),
        )}
      </ul>

      <div class={styles.form} style={{ "--color-input-border-focused": "var(--color-user-accent)" }}>
        <MoreMenu
          $$open={$$attachmentMenuIsOpen}
          preferHorizontalAlignment="right"
          preferVerticalAlignment="above"
          icon={<Plus />}
          color={$userColor}
          options={[
            {
              label: "INSERT LINK",
              icon: <Link />,
              callback: () => {
                $$linkMenuIsOpen.set(true);
              },
            },
            {
              label: "ATTACH FILES",
              icon: <Paperclip />,
              callback: () => {
                promptForFiles({ multiple: true, accept: "*" }).then(addFiles);
              },
            },
          ]}
        />

        <div class={styles.editor}>
          <div class={[markdownStyles.markdown]} ref={$$editorElement} />
          {cond($$showPlaceholder, <span class={styles.placeholder}>{$placeholder}</span>)}
        </div>

        <Button
          type="button"
          tooltip={translate("workspace.chat.inputBox.buttonToolTip")}
          disabled={$($canSend, (x) => !x)}
          onClick={onSend}
        >
          {translate("workspace.chat.inputBox.buttonText")}
        </Button>
      </div>

      <HoverMenu
        $$open={$$linkMenuIsOpen}
        $anchorRef={$($$editorElement)}
        distanceFromAnchor={12}
        preferVerticalAlignment="above"
        preferHorizontalAlignment="center"
      >
        <div class={[styles.searchMenu, scrollStyles.scrollable]}>
          <SearchMenu
            allowedTypes={["notes", "tasks"]}
            onSelected={(item) => {
              ctx.log("link selected", item);
              const selection = $$linkSelection.get();
              editor.insertEmbed(selection?.index ?? 0, QuackLinkBlot.blotName, item.data, "user");
              ctx.log(selection?.index, QuackLinkBlot.blotName, item);

              $$linkMenuIsOpen.set(false);
            }}
          />
        </div>
      </HoverMenu>
    </div>
  );
}

interface InputAttachmentProps {
  $file: Readable<InputFile>;
  onRemove: (file: InputFile) => void;
}

// Items have a set height, but variable width based on their aspect ratio.
// Attachments are horizontally aligned in one row that can scroll when there are enough items.
export function InputAttachment(props: InputAttachmentProps, ctx: ViewContext) {
  // TODO: Support returning plain DOM nodes from views and passing them as children.
  // This invalidates the need for refs a lot of the time and makes more sense if you're familiar with browser APIs.

  // const img = document.createElement("img");

  const $$imgElement = $$<HTMLImageElement>();
  const $$type = $$<string>("");
  const $$bytes = $$<string>("");
  const $$progress = $$<number>(0);

  let lastFileId: string;

  ctx.observe(props.$file, ({ uploadId, file, progress }) => {
    if (uploadId !== lastFileId) {
      const reader = new FileReader();
      $$bytes.set(prettyBytes(file.size));

      if (file.type !== "") {
        const type = file.type.split("/")[0].toLowerCase();
        $$type.set(type[0].toUpperCase() + type.slice(1));
      } else {
        $$type.set("File");
      }

      reader.onload = function (e) {
        $$imgElement.get()!.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);

      lastFileId = uploadId;
    }

    ctx.log(progress);
    $$progress.set(progress);
  });

  return (
    <li class={styles.inputFormAttachment}>
      <div class={styles.attachmentToolbar}>
        <div class={styles.attachmentMeta}>
          <span class={styles.attachmentTypeLabel}>{$$type}</span>
          <span class={styles.attachmentSizeLabel}>{$$bytes}</span>
        </div>

        <IconButton
          variant="destructive"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            props.onRemove(props.$file.get());
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <img ref={$$imgElement} src="" alt="" />
      <progress class={styles.attachmentProgressBar} value={$$progress} />
    </li>
  );
}
