import {
  $,
  cond,
  DialogStore,
  Readable,
  Renderable,
  repeat,
  RouterStore,
  ViewContext,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { ChatStore } from "@stores/ChatStore";
import { NavStore } from "@stores/NavStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { ThemeStore } from "@stores/ThemeStore";
import Chat from "@views/@icons/Chat";
import ChatLine from "@views/@icons/ChatLine";
import Heart from "@views/@icons/Heart";
import HeartLine from "@views/@icons/HeartLine";
import Menu from "@views/@icons/Menu";
import Search from "@views/@icons/Search";
import styles from "./PlaceHeader.module.css";

export interface Tab {
  text: string | Readable<string>;
  href: string | Readable<string>;
  active: (pattern: string) => boolean;
  hidden?: boolean | Readable<boolean>;
}

interface PlaceHeaderProps {
  $title: Readable<string>;
  $icon?: Readable<Renderable>;
  $coverImageURL?: Readable<string | undefined>;
  tabs?: Tab[] | Readable<Tab[]>;
}

export function PlaceHeader(props: PlaceHeaderProps, ctx: ViewContext) {
  const auth = ctx.getStore(AuthStore);
  const router = ctx.getStore(RouterStore);
  const nav = ctx.getStore(NavStore);
  const chat = ctx.getStore(ChatStore);
  const projects = ctx.getStore(ProjectsStore);
  const theme = ctx.getStore(ThemeStore);
  const dialog = ctx.getStore(DialogStore);

  const $menuBadgeNumber = $(auth.$updateAvailable, projects.$invites, (hasUpdate, invites) => {
    let count = invites.length;
    if (hasUpdate) {
      count += 1;
    }
    return count;
  });

  const $currentPageIsFavorite = $(router.$path, nav.$favorites, (path, favorites) =>
    favorites.some((f) => f.path === path),
  );

  const $chatTheme = theme.getThemeVariables$(
    $(chat.$$projectId, projects.$cache, (id, projects) => projects.find((p) => p.id === id)?.color),
  );

  return (
    <header
      class={[styles.header, { [styles.tabless]: props.tabs == null }]}
      style={{ backgroundImage: "url(/textures/inspiration-geometry.png)" }}
    >
      <div class={styles.titleArea}>
        <button
          class={styles.titleButton}
          onClick={() => {
            nav.togglePlaceMenu();
          }}
        >
          <div
            class={[styles.iconButton, styles.titleIndicatorIcon, { [styles.active]: nav.$placeMenuIsOpen }]}
          >
            {cond($menuBadgeNumber, <span class={styles.badge}>{$menuBadgeNumber}</span>)}
            <Menu />
          </div>
          <div class={styles.titleIcon}>{props.$icon}</div>
          <h1>{props.$title}</h1>
        </button>

        <div class={styles.titleControls}>
          <button
            class={[styles.iconButton, styles.vibrant, { [styles.active]: $currentPageIsFavorite }]}
            style={$(theme.getThemeVariables$("#de2d34"), $currentPageIsFavorite, (themeVars, isFave) => {
              if (isFave) {
                return themeVars;
              } else {
                return {};
              }
            })}
            type="button"
            onClick={() => {
              if ($currentPageIsFavorite.get()) {
                const path = router.$path.get();
                const favorite = nav.$favorites.get().find((f) => f.path === path);
                if (favorite) {
                  nav.removeFavorite(favorite.id);
                }
              } else {
                nav.addFavorite(router.$path.get());
              }
            }}
          >
            {cond($currentPageIsFavorite, <Heart />, <HeartLine />)}
          </button>

          <button
            class={styles.iconButton}
            type="button"
            onClick={() => {
              nav.openSearch();
            }}
          >
            <Search />
          </button>

          <button
            class={[styles.iconButton, { [styles.active]: chat.$isOpen }]}
            style={$($chatTheme, chat.$isOpen, (themeVars, isOpen) => {
              if (isOpen) {
                return themeVars;
              } else {
                return {};
              }
            })}
            type="button"
            onClick={() => {
              chat.toggle();
            }}
          >
            {cond(chat.$isOpen, <Chat />, <ChatLine />)}
          </button>
        </div>
      </div>

      {cond(
        props.tabs,
        <nav class={styles.nav}>
          <ul>
            {repeat(
              props.tabs!,
              (tab) => $(tab.href).get(),
              ($tab) => (
                <li class={{ [styles.hidden]: $($tab, (t) => t.hidden) }}>
                  <a
                    href={$($tab, (t) => t.href)}
                    class={{
                      [styles.active]: $(router.$pattern, $tab, (pattern, tab) =>
                        pattern ? tab.active(pattern) : false,
                      ),
                    }}
                  >
                    {$($tab, (t) => t.text)}
                  </a>
                </li>
              ),
            )}
          </ul>
          <div class={styles.buttons}></div>
        </nav>,
      )}
    </header>
  );
}
