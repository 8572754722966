export default {
  "header": "PlaceHeader_header_c9d91",
  "tabless": "PlaceHeader_tabless_c9d91",
  "titleArea": "PlaceHeader_titleArea_c9d91",
  "titleButton": "PlaceHeader_titleButton_c9d91",
  "titleIndicatorIcon": "PlaceHeader_titleIndicatorIcon_c9d91",
  "titleIcon": "PlaceHeader_titleIcon_c9d91",
  "nav": "PlaceHeader_nav_c9d91",
  "active": "PlaceHeader_active_c9d91",
  "hidden": "PlaceHeader_hidden_c9d91",
  "titleControls": "PlaceHeader_titleControls_c9d91",
  "iconButton": "PlaceHeader_iconButton_c9d91",
  "vibrant": "PlaceHeader_vibrant_c9d91",
  "badge": "PlaceHeader_badge_c9d91",
  "chatButton": "PlaceHeader_chatButton_c9d91"
};import "ni:sha-256;Q5fM8Zn_0yVFkEKLLv6v2KTw39pevkpNIlUHYqDwNcs";